import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ColorSwatches from "~/components/ColorSwatches/ColorSwatches";
import TokenTable from "~/components/TokenTable/TokenTable";
export const _frontmatter = {
  "menuLabel": "Spacing",
  "sortOrder": 5.5
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Spacing`}</h1>
    <p className="p-large">Spacing tokens help create visual balance and harmony in a UI.</p>
    <p>{`These tokens should be used for all padding, margin, and position coordinates.`}</p>
    <TokenTable tokenGroup="Spacing" type="Semantic" mdxType="TokenTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      